import React from 'react'
import shortid from 'shortid'
import { Box, Link } from 'rebass/styled-components'

// COMPONENTS
import SEO from './../components/templates/seo';
import Section from './../components/atoms/section';
import Heading from './../components/atoms/heading';
import Text from './../components/atoms/text';

export default function Prywatnosc(props) {

    const articles = [
        `Nasz serwis nie zbiera w sposób automatyczny 
        żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.`,
        `Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, 
        w szczególności pliki tekstowe, które przechowywane są w urządzeniu 
        końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze 
        stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę 
        strony internetowej, z której pochodzą, czas przechowywania ich na 
        urządzeniu końcowym oraz unikalny numer.`,
        {
            article: `Pliki cookies wykorzystywane są w celu:`,
            points: [
                `- dostosowania zawartości stron internetowych 
                Serwisu do preferencji Użytkownika oraz optymalizacji 
                korzystania ze stron internetowych; w szczególności 
                pliki te pozwalają rozpoznać urządzenie Użytkownika 
                Serwisu i odpowiednio wyświetlić stronę internetową, 
                dostosowaną do jego indywidualnych potrzeb;`,
                `- tworzenia statystyk, które pomagają zrozumieć, 
                w jaki sposób Użytkownicy Serwisu korzystają ze 
                stron internetowych, co umożliwia ulepszanie 
                ich struktury i zawartości;`,
                `- utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), 
                dzięki której Użytkownik nie musi na każdej podstronie 
                Serwisu ponownie wpisywać loginu i hasła;`
            ]
        },
        `W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: 
        „sesyjne” (session cookies) oraz „stałe” (persistent cookies). 
        Cookies „sesyjne” są plikami tymczasowymi, które przechowywane 
        są w urządzeniu końcowym Użytkownika do czasu wylogowania, 
        opuszczenia strony internetowej lub wyłączenia oprogramowania 
        (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są 
        w urządzeniu końcowym Użytkownika przez czas określony w parametrach 
        plików cookies lub do czasu ich usunięcia przez Użytkownika.`,
        {
            article: `W ramach Serwisu stosowane są następujące rodzaje plików cookies:`,
            points: [
                `- „niezbędne” pliki cookies, umożliwiające korzystanie z 
                usług dostępnych w ramach Serwisu, np. uwierzytelniające 
                pliki cookies wykorzystywane do usług wymagających uwierzytelniania 
                w ramach Serwisu;`,
                `- pliki cookies służące do zapewnienia bezpieczeństwa, 
                np. wykorzystywane do wykrywania nadużyć w zakresie 
                uwierzytelniania w ramach Serwisu;`,
                `- „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji 
                o sposobie korzystania ze stron internetowych Serwisu;`,
                `- „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” 
                wybranych przez Użytkownika ustawień i personalizację interfejsu 
                Użytkownika, np. w zakresie wybranego języka lub regionu, 
                z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony 
                internetowej itp.;`,
                `- „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.`
            ]
        },
        `W wielu przypadkach oprogramowanie służące do przeglądania 
        stron internetowych (przeglądarka internetowa) domyślnie 
        dopuszcza przechowywanie plików cookies w urządzeniu końcowym 
        Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie 
        zmiany ustawień dotyczących plików cookies. Ustawienia te mogą 
        zostać zmienione w szczególności w taki sposób, aby blokować 
        automatyczną obsługę plików cookies w ustawieniach przeglądarki 
        internetowej bądź informować o ich każdorazowym zamieszczeniu w 
        urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości 
        i sposobach obsługi plików cookies dostępne są w ustawieniach 
        oprogramowania (przeglądarki internetowej).`,
        `Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.`,
        `Więcej informacji na temat plików cookies i sposobie wyłączenia znajduje się w odnosnikach poniżej.`
    ]


    const privacy_section_props = {
        sx: {
            width: '100%',
            height: 'auto',
            flexFlow: 'column nowrap'
        },
        padding: '0 1.25rem'
    }
    
    const privacy_heading_props = {
        as: 'h1',
        background_text: 'Ciasteczka',
        margin: '2rem auto 3.5rem auto'
    }

    const policy = articles.map((item, i) => 
        <Box key={shortid.generate()} sx={{ margin: '0 0 1rem 0' }}
        data-sal='slide-up'
        data-sal-duration='600'
        data-sal-delay='0'
        data-sal-easing='cubic-bezier(0.165, 0.84, 0.44, 1)'>
            <Text as='p' margin='0.5rem 0'>{`${i + 1}. ${item.article ? item.article : item}`}</Text>
            {
                item.points ? 
                    item.points.map(item => 
                        <Box key={shortid.generate()} sx={{ paddingLeft: '0.5rem' }}>
                            <Text as='p' margin='0.5rem 0'>{item}</Text>
                        </Box>
                    )
                : null
            }
        </Box>
    )

    return (
        <>
            <SEO title='Prywatność' />
            <Section {...privacy_section_props}>
                <Heading {...privacy_heading_props}>Prywatność</Heading>
                { policy }
                <Box sx={{ marginBottom: '5rem' }}>
                    <Heading as='h2' textAlign='left' margin='2.5rem 0' fontSize={[22, 24]}>Jak usunąć ciasteczka ?</Heading>
                    <Link sx={{ margin: '1rem 0', display: 'block' }} 
                    href='https://help.opera.com/en/latest/web-preferences/#cookies' target='_blank' rel='noopener noreferrer'>- w przeglądarce Opera</Link>
                    <Link sx={{ margin: '1rem 0', display: 'block' }} 
                    href='https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectlocale=pl&redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek' target='_blank' rel='noopener noreferrer'>- w przegladarce Firefox</Link>
                    <Link sx={{ margin: '1rem 0', display: 'block' }} 
                    href='https://support.google.com/chrome/answer/95647?hl=pl' target='_blank' rel='noopener noreferrer'>- w przeglądarce Chrome</Link>
                    <Link sx={{ margin: '1rem 0', display: 'block' }} 
                    href='https://support.microsoft.com/en-us/hub/4338813/windows-help' target='_blank' rel='noopener noreferrer'>- w przeglądarce Microsoftu</Link>
                    <Link sx={{ margin: '1rem 0', display: 'block' }} 
                    href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac' target='_blank' rel='noopener noreferrer'>- w przeglądarce Safari</Link>
                    <Text as='p' margin='2.5rem 0 0 0'>Jeśli korzystasz z urządzenia przenośnego (telefon, smartfon, tablet), zapoznaj się z opcjami dotyczącymi ochrony prywatności w dokumentacji na stronie internetowej producenta Twojego urządzenia.</Text>
                </Box>
            </Section>
        </>
    )
}
